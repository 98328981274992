<script>
// import axios from "axios";

import DropZone from "@/components/widgets/dropZone";
import { ref } from "vue";

// import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import Layout from "../../../layouts/main";
// import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Add Product",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  setup() {
    let dropzoneFile = ref("");
    let url = ref(null);
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
      url = URL.createObjectURL(e.dataTransfer.files[0]);
      let img = document.createElement("img");
      img.src = url;
      if (document.querySelector(".img-prev").hasChildNodes()) {
        document.querySelector("#prev-img-el").remove();
      }
      img.id = "prev-img-el";
      document.querySelector(".img-prev").appendChild(img);
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector(".dropzoneFile").files[0];
      url = URL.createObjectURL(
        document.querySelector(".dropzoneFile").files[0]
      );
      let img = document.createElement("img");
      img.src = url;
      if (document.querySelector(".img-prev").hasChildNodes()) {
        document.querySelector("#prev-img-el").remove();
      }
      img.id = "prev-img-el";
      img.style.width = "150px";
      img.style.height = "150px";
      document.querySelector(".img-prev").appendChild(img);
      console.log(dropzoneFile.value);
      console.log(url);
    };
    return { url, dropzoneFile, drop, selectedFile, v$: useVuelidate() };
  },
  components: {
    DropZone,
    Layout,
    // PageHeader,
  },
  data() {
    return {
      title: "Add Product",
      items: [
        {
          text: "Ecommerce",
          href: "/",
        },
        {
          text: "Add Product",
          active: true,
        },
      ],
      value: null,
      value1: null,
      errorSubmit: false,
      categories: [],
      branches: [],
      category: {},
      image: "",
      file: "",
      loading: false,
    };
  },
  methods: {
    responseAlert(text, title, icon) {
      this.$swal.fire({
        title: `${title}`,
        text: ` ${text} `,
        icon: `${icon}`,
        toast: true,
        position: "top-start",
        showConfirmButton: false,
        timer: 2000,
      });
    },
    onAccept(file) {
      this.image = file.name;
      this.file = file;
    },
    addCateogries() {
      this.loading = true;
      if (this.category.parent_id == "null") {
        delete this.category.parent_id;
      }
      if (this.dropzoneFile) {
        this.category.image = this.dropzoneFile;
        this.http.do("cateogries", this.category).then((res) => {
          if (res.status) {
            this.dropzoneFile = {};
            if (document.querySelector(".img-prev").hasChildNodes()) {
              document.querySelector("#prev-img-el").remove();
            }
            this.category = {};
            if (this.$i18n.locale == "ar") {
              this.responseAlert("تم إضافة القسم بنجاح", " تم ", "success");
            } else {
              this.responseAlert(
                "Category added successfully",
                "done",
                "success"
              );
            }
            this.getCateogries();
          } else {
            if (this.$i18n.locale == "ar") {
              this.responseAlert("حدث خطأ اثناء إضافة القسم", "خطأ", "warning");
            } else {
              this.responseAlert("something went wrong", "error", "warning");
            }
          }
        });
        this.loading = false;
        return;
      }
      this.http.post("cateogries", this.category).then((res) => {
        if (res.status) {
          this.category = {};
          if (this.$i18n.locale == "ar") {
            this.responseAlert("تم إضافة القسم بنجاح", " تم ", "success");
          } else {
            this.responseAlert(
              "Category added successfully",
              "done",
              "success"
            );
          }
        } else {
          if (this.$i18n.locale == "ar") {
            this.responseAlert("حدث خطأ اثناء إضافة القسم", "خطأ", "warning");
          } else {
            this.responseAlert("something went wrong", "error", "warning");
          }
        }
        this.loading = false;
      });
      this.http.do("cateogries", this.category).then((res) => {
        if (res.status) {
          if (this.$i18n.locale == "ar") {
            this.responseAlert("تم إضافة القسم بنجاح", " تم ", "success");
          } else {
            this.responseAlert(
              "Category added successfully",
              "done",
              "success"
            );
          }
          this.$router.push("/cateogries");
        } else {
          if (this.$i18n.locale == "ar") {
            this.responseAlert("حدث خطأ اثناء إضافة القسم", "خطأ", "warning");
          } else {
            this.responseAlert("something went wrong", "error", "warning");
          }
        }
      });
    },
    getCateogries() {
      this.http.get("cateogries").then((res) => {
        this.categories = res.data;
      });
    },
  },

  created() {
    this.getCateogries();
  },
};
</script>

<template>
  <Layout>
    <!-- <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="row align-items-center">
        <div class="d-flex align-items-center mb-4">
          <router-link :to="`/cateogries`">
            <h4 class="m-0">
              {{ $t("menu.menuitems.categories.text") }}
            </h4>
          </router-link>
          <div class="mx-2">\</div>
          <div>{{ $t("products.new.title") }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ $t("products.new.basic_info") }}</h4>
            <p class="card-title-desc">{{ $t("products.new.fill") }}</p>
            <form @submit.prevent="addCateogries" ref="basicForm">
              <div class="row">
                <div class="col-sm-6">
                  <div class="mb-3">
                    <label for="productname">{{
                      $t("cateogries.new.name")
                    }}</label>
                    <input
                      id="productname"
                      v-model="category.name"
                      name="name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': !category.name && errorSubmit,
                      }"
                    />
                  </div>
                  <div class="mb-3">
                    <label for="productname">{{
                      $t("cateogries.new.name_ar")
                    }}</label>
                    <input
                      id="productname"
                      v-model="category.name_ar"
                      name="name"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid': !category.name_ar && errorSubmit,
                      }"
                    />
                  </div>
                </div>

                <div class="col-sm-6">
                  <label>{{ $t("products.new.img") }}</label>
                  <DropZone @drop.prevent="drop" @change="selectedFile()" />

                  <div class="my-2 img-prev"></div>
                </div>
              </div>
              <div class="mt-2">
                <button
                  v-if="!loading"
                  type="submit"
                  class="btn btn-primary"
                  style="margin-inline-end: 8px"
                >
                  {{ $t("popups.save") }}
                </button>
                <button
                  v-if="loading"
                  disabled
                  type="submit"
                  class="btn btn-primary"
                  style="margin-inline-end: 8px"
                >
                  <b-spinner small></b-spinner>
                  <span class="sr-only">loading ...</span>
                </button>
                <router-link to="cateogries"
                  ><button class="btn btn-secondary">
                    {{ $t("popups.cancel") }}
                  </button></router-link
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
